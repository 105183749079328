'use strict';

// stylesheet import
// import '../sass/main.sass';
import '../scss/main.scss';

import { createApp } from 'vue';
import { store } from './store.js';
import App from './vue/app.vue';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
const $ = require('jQuery');
import Swiper from 'swiper';
import 'swiper/css';

// Vue.js
const app = createApp({});
app.component('app', App)
  .use(store)
  .mount('#app');
  
// jQuery
$(function() {

  // accordion
  $('.js-accordion-summary').on('click', function() {
    $(this).next('.js-accordion-detail').slideToggle();
    $(this).toggleClass('js-accordion-summary--opened');
  });

  // drawer menu
  let isDrawerOpen = false;
  $('.js-hamburger').on('click', function() {
    if(isDrawerOpen) {
      $('.js-search').prop('disabled', false);  
    } else {
      $('.js-search').prop('disabled', true);  
    }
    $('.js-drawer-menu').fadeToggle();
    $(this).toggleClass('js-hamburger--close');
    isDrawerOpen = !isDrawerOpen;
    
  });
  $('.js-search').on('click', function() {
    $('.js-drawer-menu').fadeIn();
    $(this).prop('disabled', true);
    $('.js-hamburger').addClass('js-hamburger--close');
    isDrawerOpen = true;
  });
  $(window).on('resize', function() {
    $('.js-hamburger').removeClass('js-hamburger--close');
    $('.js-drawer-menu').fadeOut();
    $('.js-search').prop('disabled', false);
  });

  // megamenu & rollover image 
  function showMenu() {
    $('.js-mega-menu-content').show();
    $('.js-rollover-image').attr('src', $('.js-rollover-image').attr('data-hover'));
    $('.c-overlay-menu--trigger').addClass('c-overlay-menu--trigger-hover');
    overlayInit();
  }
  function closeMenu() {
    $('.js-mega-menu-content').hide();
    $('.js-rollover-image').attr('src', $('.js-rollover-image').attr('data-src'));
    $('.c-overlay-menu--trigger').removeClass('c-overlay-menu--trigger-hover');
  }
  function overlayInit() {
    if(0 < $('.js-mega-menu-overlay').length) {
      const overlayPositionY = $('.js-mega-menu-overlay').offset().top;
      const targetHeight = $(document).height() - overlayPositionY;
      $('.js-mega-menu-overlay').css('height', `${targetHeight}px`);
    }
  }
  $('.js-mega-menu').on('mouseenter', function() {
    showMenu();
  });
  $('.js-mega-menu').on('mouseleave', function() {
    closeMenu();
  });
  $(window).on(('resize', function() {
    closeMenu();
    overlayInit();
  }));

  // order sort select
  $('.js-order-sort-select').on('change', function() {
    location.href = $('.js-order-sort-select').val();
  });

  // form agree check
  $('.js-agree-submit input[name="submitConfirm"]').prop('disabled', true);
  $('.js-agree-check input[type="checkbox"]').prop('checked', false);
  $('.js-agree-check input[type="checkbox"]').on('change', function() {
    if($(this).prop('checked')) {
      $('.js-agree-submit input[name="submitConfirm"]').prop('disabled', false);
    } else {
      $('.js-agree-submit input[name="submitConfirm"]').prop('disabled', true);
    }
  });

  // add placeholder
  $('.js-add-placeholder input[type="text"]').attr('placeholder', '入力してください');

});

// Swiper
const swiper = new Swiper('.swiper', {
  loop: true,
  slidesPerView: 1.5,
  spaceBetween: 25,
  centeredSlides: true,
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 25,
      centeredSlides: false,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 25,
      centeredSlides: false,
    }
  }
});


// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();